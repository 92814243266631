/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Promise:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Concept"), "\nA promise is an object that represents a task tat will be completed in the future.\n", React.createElement(_components.strong, null, "Simple Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var p1 = new Promise(function(resolve, reject){\n  resolve([1,2,3,4]);\n});\n\np1.then(function(arr){\n  console.log(\"promise p1 resolved with data:\", arr);\n});\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example: Error Handling")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var p1 = new Promise(function(resolve, reject){\n  reject(\"ERROR\");\n});\n\np1.then(function(data){\n  console.log(\"Promise p1 resolved with data:\", data);\n}).catch(function(data){\n  console.log(\"Promise p1 was rejected with data:\", data);\n});\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example:")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var p1 = new Promise(function(resolve, reject){\n  var num = Math.random();\n  if(num < 0.5) {\n    resolve(num);\n  } else {\n    reject(num);\n  }\n});\n\np1.then(function(result){\n  console.log(\"Success:\", result);\n}).catch(function(error) {\n  console.log(\"Error:\", error);\n})\n")), "\n", React.createElement(_components.h3, null, "Promise: Chaining"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Why"), ": Nesting call backs can get really messy.\n", React.createElement(_components.strong, null, "Example 1: Nested Callbacks")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var counter = 0;\nsetTimeout(function(){\n  counter++;\n  console.log(\"Counter:\", counter);\n  setTimeout(function() {\n    counter++;\n    console.log(\"Counter:\", counter);\n    setTimeout(function() {\n      counter++;\n      console.log(\"Counter:\", counter);\n\n    }, 3000);\n  }, 2000);\n}, 1000);\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Why is this bad"), ":"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "code is hard to read"), "\n", React.createElement(_components.li, null, "logic is difficult to follow"), "\n", React.createElement(_components.li, null, "code is not modular (lots of duplication)"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example 2: Refactor of Example 1 with Promises")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var counter = 0;\nfunction incCounter() {\n  counter ++;\n  console.log(\"Counter\", counter);\n}\n\nfunction runLater(callback, timeInMs) {\n  var p = new Promise(function(resolve, reject) {\n    setTimeout(function() {\n      var res = callback();\n      resolve(res);\n    }, timeInMs);\n  });\n  return p;\n}\n\nrunLater(incCounter, 1000).then(function(){\n  return runLater(incCounter, 2000);\n}).then(function() {\n  return runLater(incCounter, 3000);\n}).then(function() {\n  // final .then not necessary\n});\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example 3")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var promise = new Promise(function(resolve, reject){\n  resolve(5);\n});\n\npromise.then(function(data){\n  return data * 2;\n}).then(function(data){\n  return data + 20;\n}).then(function(data){\n  console.log(data);\n});\n// log 30\n")), "\n", React.createElement(_components.p, null, "A javascript promise either resolves when true or is rejected when false"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Simple Promise Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "let p = new Promise((resolve, reject) => {\n  let a = 1 + 1\n  if (a == 2) {\n    resolve(\"success\")\n  } else {\n    reject(\"failed\")\n  }\n})\n\np.then((message) => {\n  console.log(message)\n}).catch((err) => {\n  console.log(err)\n})\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
